export default {
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "backToDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to the dashboard"])},
  "communication": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication"])},
    "wrote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wrote"])},
    "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You"])}
  },
  "dashboard": {
    "actions": {
      "assessments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to language evaluation"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
      "confidentialNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidential notes"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "editEvaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit evaluation"])},
      "editReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit reference"])},
      "evaluateApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluate application"])},
      "evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
      "finalize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalize registration"])},
      "goToIncident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to incident"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review your application"])},
      "seeProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See profile"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give your feedback"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
    },
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
    "arrival_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrival date"])},
    "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrival date"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
    "birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthdate"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "current_employer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current employer"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "departure_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departure date"])},
    "departureDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departure date"])},
    "employment_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employment start date"])},
    "employment_start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employment start date"])},
    "employmentStatus": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employment status"])}
    },
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
    "filter": {
      "church": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter local churches"])},
      "employer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter employer"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for name ..."])},
      "yearGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter year group"])}
    },
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "levels": {
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["level"])},
      "speak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speak"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last level"])},
      "understand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Understand"])}
    },
    "local_church": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local church"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "noApplicationAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No application available"])},
    "noChurchApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No application from your church"])},
    "noIncident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No incident"])},
    "noLanguageClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No class registered"])},
    "noParticipantInLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No participant in this location"])},
    "noPersonalApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You did not send your application yet. Send it"])},
    "norwegianTeacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norwegian teacher"])},
    "on_track": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On track"])}
    },
    "participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participant"])},
    "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
    "potential_placement": {
      "arrangement_ledelse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrangement / leadership"])},
      "byggeplass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byggeplass"])},
      "byggeplass_germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byggeplass Germany"])},
      "byggeplass_norway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byggeplass Norway"])},
      "canada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada"])},
      "carpenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carpenter"])},
      "cleaning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cleaning"])},
      "communication_coordinator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication / coordinator"])},
      "drift_brunstad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drift Brunstad"])},
      "electrician": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electrician"])},
      "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
      "germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germany"])},
      "graphic_design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphic design"])},
      "horeca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HoReCa"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT"])},
      "lagerjobb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouse job"])},
      "llb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLB"])},
      "marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing"])},
      "media_and_redaksjon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media & Redaksjon"])},
      "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Music"])},
      "north_germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["North Germany"])},
      "plumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plumber"])},
      "south_germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Germany"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potential placement"])},
      "work_assistant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work assistant"])},
      "yep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YEP"])}
    },
    "progress": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])}
    },
    "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
    "status": {
      "done": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Got ", _interpolate(_named("count")), " feedback"])},
      "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for feedback"])}
    }
  },
  "employment": {
    "backToParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to participant"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit employment details"])}
  },
  "finance": {
    "accountDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account details"])},
    "accountInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to pay from a Norwegian account, make a transfer to the below account. Otherwise, use the \"Pay now\" button above."])},
    "accountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account number"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
    "cancelledPayment": {
      "goToPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back to My Finances in order to proceed to checkout."])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have cancelled the payment."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled payment"])}
    },
    "cancelledSubscription": {
      "goToSubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back to My Finances to start again."])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have cancelled monthly subscription!"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled subscription"])}
    },
    "customAmountPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom defined amount"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deposit"])},
    "info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For the time being, all future statuses will be communicated via email. You will receive a monthly invoice at the end of each month which is due the following month on the 20th. If you have any questions regarding the invoice, contact ", _interpolate(_named("email")), "."])},
    "monthlySubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly subscription"])},
    "noBudget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No budget set up for this participant."])},
    "noPrePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pre-payment set up for this participant."])},
    "noStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no status available for your account."])},
    "notSubscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not subscribed"])},
    "oneTimePayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-time payments"])},
    "payNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay now"])},
    "services": {
      "Buk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camp/conference"])},
      "Kontingent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership dues"])}
    },
    "statuses": {
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
      "partially": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partially paid"])},
      "scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planned"])},
      "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for payment"])}
    },
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
    "subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed"])},
    "successfulPayment": {
      "questions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If you have any questions, please email ", _interpolate(_named("email")), "."])},
      "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your payment!"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful payment"])}
    },
    "successfulSubscription": {
      "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have created a monthly subscription!"])},
      "questions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If you have any questions, please email ", _interpolate(_named("email")), "."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful subscription"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])}
  },
  "form": {
    "additional_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional comment"])},
    "address": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street and house number"])}
    },
    "application_clip": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application clip"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record a 1 - 2 minutes application clip and tell us why you want to sign up for A-team."])}
    },
    "areasOfKnowledge": {
      "carpenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carpenter"])},
      "construction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction"])},
      "deco_graphic_design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deco / graphic design"])},
      "economy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economy"])},
      "electrician_automatics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electrician - automatics"])},
      "electrician_high_current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electrician - high current"])},
      "electrician_low_current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electrician - low current"])},
      "event_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event management"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT"])},
      "lumberjacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lumberjacks"])},
      "marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing"])},
      "mechanic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mechanic"])},
      "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Music"])},
      "online_marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing Online"])},
      "plumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plumber"])},
      "technology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What areas do you have professional experience in? (at least 1 year permanent job)"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can choose multiple areas."])}
    },
    "birth_date": {
      "ageWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Unfortunately, you are not old enough. You must be 18 years old by the start date (August 1, ", _interpolate(_named("nextProgramStartYear")), ").\nRead <a href=\"", _interpolate(_named("faqUrl")), "\" class=\"underline\">FAQ - Why do I have to turn 18 to join A-team?</a> You are also welcome to contact <a href=\"mailto:", _interpolate(_named("email")), "\" class=\"underline\">", _interpolate(_named("email")), "</a> for more information."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])}
    },
    "birth_place": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthplace"])}
    },
    "can_work_full_time": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can work in a full-time position (100%) for a full year"])}
    },
    "certificates": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificates (personnel management, Excel course, forklift license)"])}
    },
    "chooseCurrentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose today's date"])},
    "citizenship": {
      "afghanistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afghanistan"])},
      "albania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albania"])},
      "algeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algeria"])},
      "andorra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
      "angola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
      "anguilla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anguilla"])},
      "antigua_and_barbuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antigua and Barbuda"])},
      "argentina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentina"])},
      "armenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armenia"])},
      "aruba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruba"])},
      "australia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australia"])},
      "australian_oceania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australian Oceania"])},
      "austria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austria"])},
      "azerbaijan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbaijan"])},
      "bahamas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahamas"])},
      "bahrain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahrain"])},
      "bangladesh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangladesh"])},
      "barbados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbados"])},
      "belarus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belarus"])},
      "belgium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgium"])},
      "belize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belize"])},
      "benin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benin"])},
      "bermuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bermuda"])},
      "bhutan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bhutan"])},
      "bolivia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolivia"])},
      "bosnia_and_herzegovina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosnia and Herzegovina"])},
      "botswana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botswana"])},
      "brazil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brazil"])},
      "british_indian_ocean_territory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["British Indian Ocean Territory"])},
      "british_virgin_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["British Virgin Islands"])},
      "brunei_darussalam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunei Darussalam"])},
      "bulgaria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgaria"])},
      "burkina_faso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina Faso"])},
      "burundi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
      "cambodia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambodia"])},
      "cameroon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cameroon"])},
      "canada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada"])},
      "canary_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canary Islands"])},
      "cape_verde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cape Verde"])},
      "cayman_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cayman Islands"])},
      "central_african_republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central African Republic"])},
      "ceuta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceuta"])},
      "chad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chad"])},
      "chile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chile"])},
      "china": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["China"])},
      "colombia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colombia"])},
      "comoros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comoros"])},
      "costa_rica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costa Rica"])},
      "croatia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatia"])},
      "cuba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuba"])},
      "cyprus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyprus"])},
      "czech_republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech Republic"])},
      "democratic_peoples_republic_of_korea_north_korea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Democratic Peoples Republic of Korea (North Korea)"])},
      "democratic_republic_of_the_congo_zaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Democratic Republic of the Congo (Zaire)"])},
      "denmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denmark"])},
      "djibouti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Djibouti"])},
      "dominica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominica"])},
      "dominican_republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominican Republic"])},
      "ecuador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecuador"])},
      "egypt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egypt"])},
      "el_salvador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Salvador"])},
      "equatorial_guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equatorial-Guinea"])},
      "eritrea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eritrea"])},
      "estonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonia"])},
      "etiopia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiopia"])},
      "falkland_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falkland Islands"])},
      "faroe_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faroe Islands"])},
      "fiji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiji"])},
      "finland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finland"])},
      "france": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["France"])},
      "french_polynesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French Polynesia"])},
      "gabon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabon"])},
      "gambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambia"])},
      "georgia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Georgia"])},
      "germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
      "ghana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghana"])},
      "gibraltar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibraltar"])},
      "great_britain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Great Britain"])},
      "greece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greece"])},
      "greenland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greenland"])},
      "grenada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenada"])},
      "guam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guam"])},
      "guatemala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala"])},
      "guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea"])},
      "guinea_bissau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea-Bissau"])},
      "guyana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyana"])},
      "haiti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haiti"])},
      "honduras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
      "hungary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungary"])},
      "iceland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iceland"])},
      "india": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["India"])},
      "indonesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesia"])},
      "iran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iran"])},
      "iraq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iraq"])},
      "ireland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ireland"])},
      "israel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Israel"])},
      "italy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italy"])},
      "ivory_coast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ivory Coast"])},
      "jamaica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamaica"])},
      "japan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japan"])},
      "jordan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordan"])},
      "kazakhstan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazakhstan"])},
      "kenya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenya"])},
      "kiribati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiribati"])},
      "kosovo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosovo"])},
      "kuwait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuwait"])},
      "kyrgyzstan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyrgyzstan"])},
      "laos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
      "latvia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latvia"])},
      "lebanon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebanon"])},
      "lesotho": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesotho"])},
      "liberia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liberia"])},
      "libya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libya"])},
      "liechtenstein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
      "lithuania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lithuania"])},
      "luxembourg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxembourg"])},
      "macau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macau"])},
      "macedonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macedonia"])},
      "madagascar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagascar"])},
      "malawi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malawi"])},
      "malaysia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malaysia"])},
      "maldives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maldives"])},
      "mali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mali"])},
      "malta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
      "marshall_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marshall Islands"])},
      "mauritania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritania"])},
      "mauritius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritius"])},
      "mayotte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayotte"])},
      "melilla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melilla"])},
      "mexico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mexico"])},
      "moldova": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldova"])},
      "monaco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monaco"])},
      "mongolia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongolia"])},
      "montenegro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montenegro"])},
      "montserrat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montserrat"])},
      "morocco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morocco"])},
      "mozambique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozambique"])},
      "myanmar_burma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myanmar (Burma)"])},
      "namibia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namibia"])},
      "nauru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
      "nepal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepal"])},
      "netherlands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netherlands"])},
      "netherlands_antilles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netherlands Antilles"])},
      "new_caledonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Caledonia"])},
      "new_zealand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Zealand"])},
      "new_zealand_oceania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Zealand Oceania"])},
      "nicaragua": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicaragua"])},
      "niger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niger"])},
      "nigeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigeria"])},
      "norway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norway"])},
      "nothern_mariana_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nothern Mariana Islands"])},
      "oman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oman"])},
      "pakistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakistan"])},
      "palau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palau"])},
      "panama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panama"])},
      "papua_new_guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papua New-Guinea"])},
      "paraguay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraguay"])},
      "peru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peru"])},
      "philippines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Philippines"])},
      "pitcairn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitcairn"])},
      "poland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poland"])},
      "polar_regions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polar regions"])},
      "portugal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
      "qatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatar"])},
      "republic_of_korea_south_korea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Republic of Korea (South Korea)"])},
      "republic_of_the_congo_brazzaville": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Republic of the Congo (Brazzaville)"])},
      "romania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romania"])},
      "russia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russia"])},
      "rwanda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rwanda"])},
      "samoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
      "san_marino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Marino"])},
      "sao_tome_and_principe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao Tome and Principe"])},
      "saudi_arabia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saudi-Arabia"])},
      "senegal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senegal"])},
      "serbia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbia"])},
      "seychelles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seychelles"])},
      "sierra_leone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Leone"])},
      "singapore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapore"])},
      "slovakia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovakia"])},
      "slovenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenia"])},
      "solomon_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solomon Islands"])},
      "somalia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somalia"])},
      "south_africa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Afrika"])},
      "south_sudan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Sudan"])},
      "spain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spain"])},
      "sri_lanka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
      "st_helena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["St. Helena"])},
      "st_kitts_and_nevis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["St. Kitts and Nevis"])},
      "st_lucia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["St. Lucia"])},
      "st_pierre_and_miquelon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["St. Pierre and Miquelon"])},
      "st_vincent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["St. Vincent"])},
      "sudan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudan"])},
      "suriname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suriname"])},
      "swaziland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swaziland"])},
      "sweden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sweden"])},
      "switzerland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switzerland"])},
      "syria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syria"])},
      "taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taiwan"])},
      "tajikistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajikistan"])},
      "tanzania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanzania"])},
      "thailand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thailand"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citizenship"])},
      "togo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
      "tonga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are not European but have the possibility to apply for a European citizenship, please let us know."])},
      "trinidad_and_tobago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinidad and Tobago"])},
      "tunisia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunisia"])},
      "turkey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkey"])},
      "turkmenistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkmenistan"])},
      "turks_and_caicos_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turks and Caicos Islands"])},
      "tuvalu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
      "uganda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uganda"])},
      "ukraine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraine"])},
      "united_arab_emirates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United Arab Emirates"])},
      "united_states_of_america": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United States of America (USA)"])},
      "united_states_of_micronesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United States of Micronesia"])},
      "uruguay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruguay"])},
      "us_virgin_islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["US Virgin Islands"])},
      "uzbekistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzbekistan"])},
      "vanuatu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])},
      "vatican": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vatican"])},
      "venezuela": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venezuela"])},
      "vietnam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnam"])},
      "wallis_and_futuna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallis and Futuna"])},
      "west_bank_gaza_strip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["West Bank / Gaza strip"])},
      "yemen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yemen"])},
      "zambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zambia"])},
      "zimbabwe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbabwe"])}
    },
    "citizenshipPass": {
      "have": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have a passport"])},
      "will_apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Will apply"])}
    },
    "city": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])}
    },
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "communication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication"])},
    "completed_learning": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job title"])}
    },
    "completedStudy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed university studies"])},
      "too_young": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too young"])},
      "will_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, at start of program"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])}
    },
    "completedVocationalTraining": {
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed education"])},
      "will_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, at start of program"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])}
    },
    "confirmAndContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm and continue"])},
    "confirmNewDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click \"Save\" to start uploading the new documents to your application."])},
    "consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consent"])},
    "continueToWorkInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to save your progress and confirm that you want to continue your application with the next part coming from WorkNTravel."])},
    "country_code": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country code"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the phone number in the correct format: +49123456789"])}
    },
    "country": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])}
    },
    "criminal_record_check": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criminal record check"])}
    },
    "cv": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curriculum vitae"])}
    },
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The information you provide will be used to process your application. In this regard, we may share the information you provide here with your current youth leader and future placement responsibles. We look forward to getting to know you.\n\nFor more information about the program, read this: <a href=\"https://my.ateam.bcc.no/info/generell-info/\" class=\"underline\" target=\"_blank\">General information about the program</a>\n\nGood to know:<ul><li>You can start the application now and complete it later by selecting \"Save draft\"</li><li>Fields marked with * are mandatory - some documents (e.g. police certificate) can be added to the application afterwards.</li><li>All must be at A1-level in Norwegian when applying to reach the required level (A2/B1) by the start of the program. If you have not started studying, you can complete the following courses before the first test at the end of August: <a href=\"https://t.me/+jbWsMn5oTXBlMmVk\" target=\"_blank\" class=\"underline\">A1 intensive course</a></li><li>Remember to complete your application and select \"Send\" by the deadline!</li><li>If you have any questions, please contact our Helpdesk:<ul><li>Email: ", _interpolate(_named("email")), "</li><li>Telegram: +4797197463</li></ul></li></ul>\nWe look forward to receiving your application!"])},
    "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
    "documents_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following file formats are possible: Word (doc, docx), PDF, JPG, PNG, GIF. A single document must not exceed 15 MB in size. Please do not upload PDF and Word documents that are password-protected or write-protected."])},
    "double_citizenship": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double citizenship"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you already have a second passport, or do you have the possibility to apply for that? Choose the country."])}
    },
    "driver_license_comment": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have any comments regarding the driving licence?"])}
    },
    "driver_license_copy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driving licence"])}
    },
    "driver_license_since": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When did you get your driving licence (car)"])}
    },
    "driverLicense": {
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I don't have and won't have driving licence"])},
      "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "started_will_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I expect to get my driving licence before start"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have driving licence?"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have driving licence already"])}
    },
    "driverLicenseClasses": {
      "excavator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dredgers"])},
      "forklift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forklift"])},
      "passenger_car_automatic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car - automatic"])},
      "passenger_car_gear_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car - gear shift"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which driver's license classes do you have?"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please tick all vehicles for which you have a registration."])},
      "tractor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tractor"])},
      "trailer_drivers_license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trailer driving licence"])},
      "truck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorry"])}
    },
    "driving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driving license"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
    "educationStatus": {
      "not_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did not start"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Started and not completed"])},
      "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "will_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Will complete before the start of the program"])}
    },
    "email_address": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])}
    },
    "emergency_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency contact"])},
    "english_language_level": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norwegian, English and German are currently primarily relevant for our cooperation partners. Therefore we need your level of knowledge for each of the 3 languages."])}
    },
    "errors": {
      "applicationUpdateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while saving your application."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid e-mail address."])},
      "mustBeADateInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The value has to be in a date format (YYYY-MM-DD). You can use the datepicker window."])},
      "referenceUpdateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while saving your reference."])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required."])},
      "submissionFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while sending your application."])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You did not accept the electronic storage of the data."])},
      "uploadError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There was a problem with the file <strong>", _interpolate(_named("file")), "</strong> and could not be uploaded."])}
    },
    "european_health_insurance_card": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["European Health Card"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have an European Health Card, you can upload it here. You find it on the backside of the normal insurance card."])}
    },
    "first_name": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your full name as it appears in your identity card."])}
    },
    "food_intolerance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have any food allergies?"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have any food intolerances, please enter them here."])}
    },
    "former_participant": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you been on A-Team before?"])}
    },
    "former_participation_date": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From year to year"])}
    },
    "free_time_activities": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What do you like to do in your spare time?"])}
    },
    "full_time_job": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you been employed in a full-time position (at least 1 year)?"])}
    },
    "gender": {
      "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
      "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
      "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])}
    },
    "general_info": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure that the correct email and phone number is entered here. You will receive the next inquiry via email."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General information"])}
    },
    "german_language_level": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norwegian, English and German are currently relevant languages for all of our work partners. Therefore we need your level of knowledge for each of the 3 languages."])}
    },
    "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health information"])},
    "health_information": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noe helseinformasjon du ønsker å dele som kan være viktig med tanke på type arbeidstilbud du får? Noe du ønsker din stedsansvarlig skal vite om?"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you would like to tell us something about your state of health so that we can adjust the work tasks if necessary, you can write this here."])}
    },
    "healthy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am healthy enough to work"])}
    },
    "hobbies": {
      "cooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooking"])},
      "esports_gaming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Sport / Gaming"])},
      "football": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soccer"])},
      "foto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
      "graphic_design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphic Design"])},
      "ice_hockey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icehockey"])},
      "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
      "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Music"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leisure interests"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We would like to know what your leisure interests are."])},
      "volleyball": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volleyball"])}
    },
    "inputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in answer"])},
    "insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance"])},
    "international_experience_comment": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have a comment about working abroad?"])}
    },
    "international_experience_longer_than_3_months": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you worked in another country for longer than 3 months?"])}
    },
    "international_insurance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International insurance policy"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you don't have an International insurance yet, you can apply for one and send the document later."])}
    },
    "knowledgeExperienceAreas": {
      "accounting_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounting"])},
      "animation_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation"])},
      "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
      "automatician_low_voltage_fitter_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatician / Low voltage fitter"])},
      "cabinet_maker_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabinet maker"])},
      "car_detailing_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car detailing"])},
      "carpenter_woodworking_assistant_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carpenter / Woodworking assistant"])},
      "carpenter_woodworking_with_trade_certificate_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carpenter / Woodworking with trade certificate"])},
      "choose_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose area ..."])},
      "cleaning_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cleaning services"])},
      "composing_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composing music"])},
      "construction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction"])},
      "construction_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction"])},
      "construction_installation_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction installation (glass railing or pergola type)"])},
      "cooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooking"])},
      "cooking_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooking for more than 10 people"])},
      "deco_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deco"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all where you have at least one year of experience."])},
      "design_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
      "diverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse"])},
      "electrician_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electrician"])},
      "electronic_music_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electric music / Proback"])},
      "event_arrangement_leadership_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event / Arrangement leadership"])},
      "film_editing_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Film editing"])},
      "film_production_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Film production"])},
      "first_aid_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First aid"])},
      "game_development_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game development"])},
      "glass_railing_installation_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glass railing installation"])},
      "graphics_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Picture"])},
      "groundwork_landscaping_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groundwork / landscaping"])},
      "helpdesk_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk"])},
      "industrial_electricity_textile_production_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industrial sewing / Textile production"])},
      "instruments_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playing 1 or more instruments"])},
      "interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to develop myself further"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT"])},
      "kindergarten_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kindergarten"])},
      "levels": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None or very little"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A little experience but have interest"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have delivered successful projects / performances"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have studied (also self-study) and work on it regularly (also voluntary work)"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional experience or have had a job"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels legend"])}
      },
      "light_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lighting"])},
      "llb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AV"])},
      "logistics_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistics"])},
      "machine_operator_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine operator"])},
      "media_film": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media and Film"])},
      "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Music"])},
      "office_administration_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office / Administration"])},
      "office_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office work"])},
      "other_it_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other IT"])},
      "other_media_film_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other media/film"])},
      "other_music_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other music"])},
      "painting_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Painting"])},
      "plumbing_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plumbing"])},
      "production_of_bakery_products_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production of bakery products"])},
      "pruefservice_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electrical testing (Prüfservice)"])},
      "skilled_worker_concrete_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skilled worker, concrete"])},
      "software_development_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System development"])},
      "sound_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])},
      "stocktaking_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stocktaking"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over how many years you have experience in an area (including volunteer experience)?"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In which areas do you have experience?"])},
      "ux_designer_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UX designer"])},
      "ventilation_installation_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventilation installation"])},
      "waiter_catering_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiter / catering"])},
      "warehouse_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouse"])},
      "websites_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Websites"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])}
    },
    "languageLevels": {
      "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good"])},
      "little": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A little"])},
      "mother_tongue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mother tongue"])},
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not at all"])},
      "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "very_well": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very good"])}
    },
    "languages": {
      "african_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["African languages"])},
      "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
      "basque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basque"])},
      "bulgarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgarian"])},
      "chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
      "czech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech"])},
      "danish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danish"])},
      "dutch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch"])},
      "finnish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finnish"])},
      "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
      "greek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greek"])},
      "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
      "japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese"])},
      "korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean"])},
      "latvian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latvian"])},
      "lithuanian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lithuanian"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Others"])},
      "persian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persian"])},
      "polish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polish"])},
      "portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese"])},
      "romanian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romanian"])},
      "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
      "serbo_croatian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbo-Croatian"])},
      "slovak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovakian"])},
      "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
      "swedish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swedish"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Further languages"])},
      "turkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
      "vietnamese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnamese"])}
    },
    "last_name": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your full name as it appears in your identity card."])}
    },
    "leadershipResponsibilities": {
      "more_than_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, for more than 5 people"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "takes_initiative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takes initiative"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have experience in team leadership?"])},
      "up_to_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, for 1-4 people"])}
    },
    "lived_alone_shared_apartment": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you ever lived alone / in a shared accommodation?"])}
    },
    "localChurches": {
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lilienhof"])},
      "101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tønsberg"])},
      "102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urbana"])},
      "103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usselo"])},
      "104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vácduka"])},
      "105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valdivia"])},
      "106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vancouver"])},
      "109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nancy"])},
      "110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waldhausen"])},
      "111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waltrop"])},
      "114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winnipeg"])},
      "116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wien"])},
      "117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wroclaw"])},
      "119": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Østfold"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecticut"])},
      "120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andre"])},
      "122": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valdres"])},
      "147": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Zealand"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delaware"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detroit"])},
      "165": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paradera"])},
      "168": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paris"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didcot"])},
      "170": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steinseltz"])},
      "172": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shanghai"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drammen Sande"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eiker"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dürrmenz"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittewald"])},
      "250": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenia"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graz"])},
      "267": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shenzhen"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenland"])},
      "286": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estland"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groningen"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twente"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross-Godems"])},
      "305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ozornoe"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallingdal"])},
      "327": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crasna"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hamar"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hamburg"])},
      "345": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brasov"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exter"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harstad"])},
      "370": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slobozia Mare"])},
      "371": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangalore"])},
      "373": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Africa"])},
      "374": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pretoria"])},
      "376": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alwaye"])},
      "377": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mallorca"])},
      "380": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medias"])},
      "381": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilo"])},
      "382": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leon"])},
      "387": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dubai"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hessenhöfe"])},
      "390": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjud"])},
      "391": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caragiale"])},
      "392": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bucharest"])},
      "396": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dorohoi"])},
      "398": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catania"])},
      "399": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curico"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holstebro"])},
      "400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofia"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hong Kong"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horten"])},
      "424": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yaoundé"])},
      "427": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ternopol"])},
      "428": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beljaevka"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hønefoss"])},
      "430": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakarpattia"])},
      "431": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cherkassy"])},
      "433": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudora"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Israel"])},
      "443": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langtore"])},
      "445": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Douala"])},
      "448": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istanbul"])},
      "450": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villa Regina"])},
      "451": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para"])},
      "452": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A-team"])},
      "455": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arequipa"])},
      "459": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunstad"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bafoussam"])},
      "478": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uganda"])},
      "479": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A-team Vanderbijlpark"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raumberg"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sørlandet"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyrkslätt"])},
      "505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svartskog"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["København"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lahti"])},
      "528": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["West-Nederland"])},
      "529": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelderland"])},
      "536": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shillong"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linnenbach"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustroń"])},
      "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maubach"])},
      "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melbourne"])},
      "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missouri"])},
      "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molde"])},
      "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Måløy"])},
      "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo Sarandi"])},
      "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oslo/Follo"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bergen"])},
      "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottawa"])},
      "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paso Flores"])},
      "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fulda"])},
      "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salem"])},
      "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandefjord"])},
      "80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sardinia"])},
      "83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seattle"])},
      "84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapore"])},
      "86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schweiz"])},
      "87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["St. Petersburg"])},
      "88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stavanger"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mumbai"])},
      "90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stord"])},
      "92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sydney"])},
      "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syracuse"])},
      "95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huntworth"])},
      "97": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toronto"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local church"])}
    },
    "locations": {
      "berlin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berlin"])},
      "duerrmenz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dürrmenz"])},
      "exter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exter"])},
      "fulda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fulda"])},
      "hamar_eiker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hamar / Eiker"])},
      "hamburg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hamburg"])},
      "hessenhoefe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hessenhöfe"])},
      "lilienhof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lilienhof"])},
      "linnenbach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linnenbach"])},
      "maubach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maubach"])},
      "muenchen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["München"])},
      "oslo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oslo"])},
      "ready_for_any_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready for any place!"])},
      "waldhausen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waldhausen"])},
      "waltrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waltrop"])},
      "wien": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wien"])}
    },
    "messagePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "military_experience": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you been in the military?"])}
    },
    "military_remarks": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have a comment on the experience you have gained in the military?"])}
    },
    "motivation_and_expectation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write briefly why you want to participate in the A-Team and what you expect."])}
    },
    "motivation_as_team_leader_comment": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have any comments on whether you are motivated to be a team leader or if you prefer to avoid it?"])}
    },
    "motivation_as_team_leader": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would a job as a team leader motivate you?"])}
    },
    "msOfficeKnowledge": {
      "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced"])},
      "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic knowledge"])},
      "excellent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent"])},
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS-Office"])}
    },
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "non_standard_placement": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-standard placement"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plasseringssteder utenfor Europa krever høyere eller ekstra utgifter som du selv må dekke (f.eks. reisen til utplasseringsstedet, ekstra forsikringer krevet av staten, ekstra reiser knyttet til innlevering av spesielle dokumenter). Dette gjelder særlig Canada. Ønsker du, i tillegg til standardplasseringene innenfor Europa, å bli vurdert for plassering utenfor Europa?"])}
    },
    "norwegian_language_level": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norwegian"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norwegian, English and German are currently primarily relevant for all cooperation partners. Therefore we need your level of knowledge for each of the 3 languages."])}
    },
    "norwegian_personal_number": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norwegian personal number / D-number"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field applies only to Norwegian citizens or others who have lived in Norway before."])}
    },
    "other_knowledge": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What other knowledge do you have? Describe."])}
    },
    "other_travel_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
    "pants_size": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trousers size"])}
    },
    "participation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participation"])},
    "passport_first_name": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name (as written in the passport)"])}
    },
    "passport_id_card": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must match the information you entered earlier under \"Passport\""])}
    },
    "passport_issue_date": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport Date of issue"])}
    },
    "passport_issue_place": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass Place of issue"])}
    },
    "passport_last_name": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name (as written in the passport)"])}
    },
    "passport_number": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport number"])}
    },
    "passport_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport status"])},
    "passport_valability": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass Valid until"])}
    },
    "personal_description": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Briefly describe yourself. What are your personal qualities? What are your strengths and weaknesses?"])}
    },
    "personal_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About you"])},
    "phone_number": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile phone"])}
    },
    "portfolio": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to portfolio"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have examples of your artwork or musical performances available online?"])}
    },
    "possible_start_date": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earliest starting date"])}
    },
    "postal_code": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])}
    },
    "preferable_tasks_in_local_church": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe what tasks you have done in your local church and eventually things have you been responsible for (e.g. AK, training, working with the budget, playing in the music)."])}
    },
    "preferred_location_comment": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We can't promise anything, but if you have a wish or something you want to say about the placement or the job offer from the partner?"])}
    },
    "preferred_location_first_priority": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred location, first priority"])}
    },
    "preferred_location_second_priority": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred location, second priority"])}
    },
    "preferred_location_third_priority": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred location, third priority"])}
    },
    "preferredCommunicationLanguages": {
      "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "norwegian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norwegian"])},
      "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred communication language"])}
    },
    "primary_citizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary citizenship"])},
    "privateInsurance": {
      "foreign_health_insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International Health Insurance"])},
      "legal_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal protection"])},
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None of them"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which insurance of the given do you already have privately?"])},
      "travel_insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel insurance"])}
    },
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "profile_picture": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portrait photo"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The face must be captured frontal. Both eyes should be fully visible. The eyes should be directed towards the camera lens. No group shots. (Please note that it may be forwarded to potential employers)."])}
    },
    "programInsurance": {
      "foreign_health_insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International Health Insurance"])},
      "legal_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal protection"])},
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I will not apply for such insurance and am aware that this is my personal risk"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which of these insurances will you get until the start?"])},
      "travel_insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel insurance"])}
    },
    "relative_address": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency contact: Address (street, house number, postcode, city, country)"])}
    },
    "relative_country_code": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency contact: Country code"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the phone number in the correct format: +49123456789"])}
    },
    "relative_email_address": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency contact: Email address"])}
    },
    "relative_name": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency contact: Last name, first name"])}
    },
    "relative_phone_number": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency contact: Mobile phone number"])}
    },
    "removeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove all"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "saveAndCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and check"])},
    "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have saved your application! You can return to it to complete it."])},
    "saveDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save draft"])},
    "school_level_on_start": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected highest completed education on the start of the program"])}
    },
    "school_level": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highest completed education today"])}
    },
    "schoolLevels": {
      "college": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eligible to apply for community college"])},
      "high_school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High school diploma or GED equvalent"])},
      "no_exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some high school completed"])},
      "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highest completed education today"])},
      "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eligible to apply for university"])}
    },
    "schoolMainActivities": {
      "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "odd_jobs_temporary_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odd jobs / temporary jobs"])},
      "practice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internship"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School"])},
      "solid_employment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permanent job"])},
      "study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current main job"])},
      "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])}
    },
    "secondary_citizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary pass"])},
    "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all"])},
    "shoesSizes": {
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 35 / US 5 / UK 3"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 36 / US 6 / UK 4"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 37 / US 6.5 / UK 4.5"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 38 / US 7.5 / UK 5.5"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 39 / US 8 / UK 6"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 40 / US 9 / UK 7"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 41 / US 9.5 / UK 7.5"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 42 / US 10 / UK 8"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 43 / US 11.5 / UK 9.5"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 44 / US 12 / UK 10"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 45 / US 13 / UK 11"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 46 / US 13.5 / UK 11.5"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 47 / US 14 / UK 12"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 48 / US 14.5 / UK 12.5"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shoe size"])}
    },
    "social_security_card": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SV Ausweis"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sozialversicherungsausweis (this is relevant for german applicants)."])}
    },
    "studies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studies"])},
    "studies_status": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is your status in terms of studies?"])}
    },
    "study_started_title": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What study have you started and not completed?"])}
    },
    "study_title": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What study have you completed at the start of the program?"])}
    },
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for your application! We are very happy that you want to join the A-team."])},
    "suitable_tasks": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What kind of tasks do you think you are suitable for? Why?"])}
    },
    "tax_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuer ID"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree that my data collected by BCC A-team can be used and shared for the purposes described in the <a href=\"https://ateam.bcc.no/privacy-policy/\" target=\"_blank\">privacy policy</a>."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration to BCC A-team"])},
    "travel_info": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everyone must have a passport (not just an ID card) by the start of the program. Are you living in a country outside Europe then the passports must be valid for 3 months after the end of the program."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citizenship and passport"])}
    },
    "tshirt_size": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T-shirt size"])}
    },
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload file"])},
    "uploadNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload new file"])},
    "vaccination_certificate_copy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaccination card"])}
    },
    "vocational_training_certified_date": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When do you plan to get your trade certificate, or when did you get it?"])}
    },
    "vocational_training_comment": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe"])}
    },
    "vocational_training_started": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What vocational training have you started and not completed?"])}
    },
    "vocational_training_status": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is your status when it comes to vocational training?"])}
    },
    "vocational_training_will_complete": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What completed vocational training do you have at the start of the program?"])}
    },
    "vocational_training": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vocational training in other countries:\n\nUSA/Canada: occupational training, vocational training, or other on-the-job training which leads to a professional certification\n\nDeutschland: Berufsbildung"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vocational training"])}
    },
    "vocationalTrainingAreas": {
      "accounting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounting"])},
      "auditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auditor"])},
      "carpenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carpenter"])},
      "cleaning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cleaning services"])},
      "cooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooking"])},
      "electrician_automatics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electrician - automatics"])},
      "electrician_high_current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electrician - high current"])},
      "electrician_low_current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electrician - low current"])},
      "industrial_mechanic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industrial mechanic"])},
      "industrial_sewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industrial sewing"])},
      "kindergarten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kindergarten"])},
      "landscaper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landscaper / Gardener"])},
      "machine_operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine operator"])},
      "mechanic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mechanic"])},
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
      "nurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nurse"])},
      "office_administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office / Administration"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other (see comment field)"])},
      "painter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Painter / Wallpaperer"])},
      "plumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plumber"])},
      "programming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programming"])},
      "road_and_construction_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Road and construction work"])},
      "skilled_worker_concrete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skilled worker, concrete"])},
      "sound_and_light_design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sound and light design"])},
      "ux_ui_design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UX/UI Design"])},
      "ventilation_installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventilation installation"])},
      "waiter_catering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiter / Catering"])},
      "woodworking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woodworking"])}
    },
    "volunteer_experience_other_remarks": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have a comment on your volunteering experiences?"])}
    },
    "volunteerExperience": {
      "cleaning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cleaning"])},
      "construction_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction works"])},
      "demolition_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demolition works"])},
      "electricity_control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electric check"])},
      "events_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event (service / catering)"])},
      "film_production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movie production"])},
      "game_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game development"])},
      "gardening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gardening"])},
      "inventory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventory"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT"])},
      "media_graphic_design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media / Graphic design"])},
      "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Music"])},
      "office_work_administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office work"])},
      "sound_light_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sound / Light / Picture"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What kind of volunteering experience do you have?"])},
      "warehouse_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage operations"])}
    },
    "work_certificates": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School certificate, apprenticeship certificate, documentation of vocational competence"])}
    },
    "work_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work clothes"])},
    "work_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work experience"])},
    "work_experience_comment": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment (on professional experience)"])}
    },
    "work_life_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General experience"])},
    "work_reference_person": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can you name one or two reference persons from your job/volunteering which our collaboration partners can eventually contact?"])},
      "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile phone number"])},
      "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["References"])},
      "work_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from which work experience"])}
    },
    "work_years_of_experience": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of years of permanent employment in the subject area"])}
    },
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["year"])},
    "year_group": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year group"])}
    },
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["years"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "yesOrNo": {
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])}
    }
  },
  "home": {
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is important that you read through this information before you arrive so that you are well prepared."])},
    "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select location"])},
    "selectYearGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select year group"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to your information portal!"])}
  },
  "incident": {
    "date": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When did this happen?"])}
    },
    "details_before": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From the week, the day, and the hours - was there overtime, was everyone tired or had little sleep, were they under pressure? What was the atmosphere like just before the event?"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe relevant details leading up to the event"])}
    },
    "follow_up_measures": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What follow-up measures have you taken since the incident?"])}
    },
    "location": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which location does this apply to?"])}
    },
    "others_involved": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have the police, media, or public figures been involved? Describe your interaction."])}
    },
    "people_involved": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everyone in the car or connected to the incident"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who was involved?"])}
    },
    "personal_impression": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is your personal impression?"])}
    },
    "reportTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident report"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incidents"])},
    "type": {
      "car_accident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car accident"])},
      "feedback_about_a_driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback about a driver"])},
      "personal_injury": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal injury"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What kind of event does it apply to?"])}
    },
    "victims_testimony": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How do those affected describe the incident?"])}
    }
  },
  "interest": {
    "rate": {
      "levels": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No interest"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low interest"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some interest"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High interest"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definitely want"])},
        "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How interested are you in this applicant?"])}
    }
  },
  "languageAssessment": {
    "backToAllEvaluations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to all evaluations"])},
    "backToLanguageOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to language overview"])},
    "editEvaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit evaluation"])},
    "form": {
      "commentsToTheStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments to the student"])},
      "evaluation_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result from this month's evaluation"])},
      "evaluation_status": {
        "not_passed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did not pass the test"])},
        "not_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not started"])},
        "passed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passed"])},
        "recommend_to_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended to wait one year"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation result"])},
        "too_low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attainment too low"])}
      },
      "language_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language comment"])},
      "language_status": {
        "needs_follow_up_norwegian_interview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Needs follow-up Norwegian interview"])},
        "norwegian_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norwegian approved"])},
        "norwegian_course_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norwegian course started"])},
        "not_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not started"])},
        "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No status"])},
        "on_track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On track"])},
        "passed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requirements passed"])},
        "ready_for_norwegian_interview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On track"])},
        "recommend_to_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient progress"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language status"])},
        "too_low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attainment too low"])}
      },
      "language_subjects": {
        "passed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passed"])},
        "passed_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passed on"])},
        "subjects": {
          "ateam_everyday_life": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A-team's everyday life"])},
          "church_life": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Church life"])},
          "city_life": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City life"])},
          "level-a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level A1"])},
          "level-a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level A2"])},
          "level-b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level B1"])},
          "youth_meeting_and_bu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youth meeting and Bible study"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can ..."])}
      },
      "more_details": {
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Eg.: learning disabilities, personality that affects the teaching process, other things you have noticed)"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is there anything else we should know about the student?"])}
      },
      "note_to_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note to student"])},
      "on_track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is the student on track to pass the Norwegian interview before A-Team starts?"])},
      "participation_in_classes": {
        "active_participation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active participation"])},
        "low_participation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low participation"])},
        "medium_participation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium participation"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participation during classes last month"])}
      },
      "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])},
      "seePreviousAssessments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See previous assessments"])},
      "studentParticipation": {
        "note": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The student has participated in classes ", _interpolate(_named("in")), " out of ", _interpolate(_named("out_of")), " times in the last two months."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participation"])}
      },
      "submission_of_homework": {
        "not_submitted_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did not submitted all"])},
        "submitted_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted all"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homeworks submission last month"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions about the norwegian course"])}
    },
    "giveEvaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give evaluation"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language evaluation"])}
  },
  "languageClass": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register participation"])},
    "goToButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language classes"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participation registration"])}
  },
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading data ..."])},
  "menu": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest"])},
    "languageClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language Class"])},
    "languageClasses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language Classes"])},
    "languageStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language status"])},
    "myFinances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Finances"])},
    "norwegianAssessments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norwegian Evaluation"])},
    "overnighting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overnighting"])},
    "participantProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participant's Profile"])},
    "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
    "placements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placements"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
    "reportIncident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report incident"])},
    "wnt-placements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WnT Placements"])}
  },
  "months": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])}
  },
  "nextMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next month"])},
  "overnighting": {
    "events": {
      "brothers_conference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Brothers conference"])},
      "feast_weekend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Feast weekend"])},
      "sisters_conference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Sisters conference"])},
      "spring_conference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Spring conference 2 (Pentecost)"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])}
    },
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are responsible for booking overnighting during BCC conferences. If your family does not have an agreement, or if your friends do not have enough space, A-team can assist in ordering an apartment (for a fee).\n\nYou can make changes to your status up until the deadline. The deadline will be communicated at a later date."])},
    "noApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You did not apply to A-team yet."])},
    "status": {
      "arranged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have arranged overnighting"])},
      "checking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'll check"])},
      "need_overnighting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to order overnighting"])},
      "no_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No answer"])},
      "not_relevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not participating"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
    }
  },
  "participants": {
    "hasSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has active subscription"])},
    "noSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does not have an active subscription"])},
    "participantsNotAjour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants who are not ajour"])},
    "participantsNotPaidThisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants who have not paid this month"])},
    "statusInPercentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status in percentage"])}
  },
  "previousMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous month"])},
  "reference": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The information you enter in this form is primarily intended to give an impression of what the applicant is like as a person. Our goal for the applicant is to have an optimal experience at A-team. Therefore, all the information you provide here is crucial to our success.\n\nResponsibility for approving the individual participant lies with the individual church and not centrally in the A-team organisation.\n\nWe emphasize the importance of talking with leaders/mentors who know who know the young people well from fundraising and other activities in order to give us the an accurate picture of the individual.\n\nThe information you provide is used centrally within the A-team administration. As needed, we may pass on select portions to other responsible persons in the A-Team program. If you have sensitive information that you think we should know about, we ask that you select \"dialog is desired\" (at the bottom of the form), instead of describing the matter in detail. We will then contact you by phone/email for further clarification. All information sent in this way is treated as strictly confidential.\n\nPlease enter your comments / feedback in the fields below."])},
    "form": {
      "approval": {
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The individual church is responsible for approving each participant. If you are in doubt about whether the applicant is suitable for the A-Team, please contact us."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We approve and recommend that the applicant be admitted to the youth program A-Team."])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have comments, general feedback or questions?"])},
      "contact": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name and phone number of contact person who knows more about the matter"])}
      },
      "contributors": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add contributor"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in name"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The reference was completed in cooperation with the following people:"])}
      },
      "conversation_with_the_applicant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is required that you conduct a personal interview with all applicants. We see this as an important part of the application process. Should the outcome be that the applicant is not approved, it is much easier to refer back to this original conversation.\nDoes the applicant understand what A-Team is about and what is expected in terms of the program?"])},
      "dialog_required": {
        "needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, dialogue is desired"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there are circumstances that you think we should pay particular attention to or that could cause challenges for the person to be able to have a good stay at the youth programme, we ask that you tick \"Yes\" here. We will then contact you for further clarification. The dialogue takes place with a person responsible for the A-Team program."])},
        "notNecessary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, dialogue is not necessary"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dialog required"])}
      },
      "driver": {
        "advise_against": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We advise against using the applicant as a driver"])},
        "dont_know": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We do not know the applicant's driving behaviour well enough to be able to make an assessment"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NB: Most applicants come to Germany. Many are not used to the German motorway and everyone must comply with the rules of driving during A-Team. It is important for us to choose drivers carefully, in order to ensure the safety of the A-Team as much as possible. If you yourself do not know the applicant's driving behavior well enough, it is important to investigate it in order to reach a conclusion."])},
        "recommandation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We recommend the applicant as a driver"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is the applicant a safe driver, follows regulations and handles cars and passengers responsibly?"])}
      },
      "health_problems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you aware of any health challenges that indicate that the applicant is not suitable for A-team? Please provide information about known health challenges - it is essential for a successful outcome."])},
      "leadership": {
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The applicant does not have typical leadership skills"])},
        "often": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The applicant has often taken leadership in such group sizes"])},
        "potential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We believe that the applicant has the potential to do so"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does the applicant have the ability to lead a team up to 3 people?"])}
      },
      "norwegian_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I confirm that the applicant will complete Norwegian language lessons in accordance with the requirements for the program before the start of the program."])},
      "personality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you experience the applicant as introverted or extroverted?"])},
      "personality2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you experience the applicant as energetic, one who takes the lead and takes initiative? Is the applicant happy to follow along when others take the lead?"])},
      "responsibility": {
        "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have seen that the applicant sees tasks and takes responsibility for ensuring that these are carried out."])},
        "needs_guidance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have seen that the applicant needs guidance and assistance to a greater extent when carrying out tasks."])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(sees tasks that need to be done and shows a good sense of responsibility to get things done)"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide an assessment of how the applicant demonstrates responsibility and performs assigned tasks."])},
        "with_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have seen that the applicant can take assignments and complete them with some guidance and help."])}
      },
      "seeOthersFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See other's feedback"])},
      "skills": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add skill"])},
        "balanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["balanced"])},
        "creative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["creative"])},
        "enduring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["perseverence"])},
        "groups_heartbeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keeps calm in a group"])},
        "hard_working": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hardworking"])},
        "motivating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["motivational"])},
        "musical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["musical"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can select several options."])},
        "otherPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
        "responsible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["responsible"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have seen the following skills in the person concerned"])},
        "well_being_creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["one who always spreads good mood around him"])}
      },
      "special_circumstances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you aware of any special incidents/circumstances/trauma in the applicant's life or family that affect the young person (e.g. divorce, death)?"])},
      "tasks_in_local_church": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the applicant has tasks or responsibilities in the local church, we ask that you write a little about the tasks here. Describe what it is like to work with the applicant"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions about skills and working methods"])},
      "work_life": {
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How do you think the applicant will cope with long working days, many new experiences, as well as living with different types of people and cultures? How adaptable and flexible is he/she to short-term changes?"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a few lines about the applicant's volunteer or work experience."])}
      },
      "workparty_contribution_and_cooperation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe a little about how the applicant contributes to voluntary work in the local church and possibly how he/she cooperates with the others."])}
    },
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Summer ", _interpolate(_named("year")), " intake reference form"])}
  },
  "registration": {
    "alreadyApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have already registered for the"])},
    "applicationsStart": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Application portal opens ", _interpolate(_named("start"))])},
    "applyAgain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If you want to apply again you can send an email to ", _interpolate(_named("email")), "."])},
    "ateam": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A-team is first and foremost an invaluable investment in your own future - for those who want to live a wholehearted disciple life!\n\nA year on A-team is a rich year of giving, service, work and personal development. You'll have rich experiences, improve your skills and learn a lot of new things. You'll get to know people from many places around the world and can make friendships that last and deepen for life. Through Bible studies and gatherings, your faith in your heavenly calling will be strengthened and you will gain a deeper understanding of what a disciple life means precisely for you. You will have rich opportunities to practice a wholehearted Christian life close to your friends while being included and contributing to the local church you belong to. After a year on A-team, you will be able to go home as a prepared mentor, with a burning desire to serve and give your life in the ministry for the saints.\n\nWhen you move to a new city or a new country, there are many details that need to be put in place. We have work partners who can offer you work, transportation and housing. Information to be passed on to our work partners can be found in the last part of the application.\n\nWe look forward to receiving your application and will make everything we can to make this year one of the best years of your life!"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is A-team?"])}
    },
    "deadlinePassed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The deadline to apply has passed on ", _interpolate(_named("deadline")), ". The applications will now end up on the waitlist. Read here for more information: <a href=\"", _interpolate(_named("faqUrl")), "\" class=\"underline\">FAQ - How does the waitlist work?</a>"])},
    "deadlineToApply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deadline for applications: ", _interpolate(_named("deadline"))])},
    "initialCheck": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in basic information to see which of BCC's youth programs and partner programs you are eligible for:"])},
      "eosMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on your selections above, you qualify for programs through BCC A-team, WorknTravel, and Samvirk.\n\nClick here to confirm that you wish to apply to these three programs simultaneously."])},
      "firstPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. The application applies to:"])},
      "nonEosMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on your choices above, you qualify for the YEP and A-team programs. You can apply for both of these at the same time through BCC Event.\n\nPlease continue with your application here: <a href=\"https://event.bcc.no/yep/\" class=\"underline\">https://event.bcc.no/yep/</a>"])},
      "secondPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are there any other countries where you have a passport or can get a passport before the program starts?"])},
      "secondPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Which country are you a citizen of?"])},
      "stepOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 1"])},
      "stepThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 3 of 3"])},
      "stepThreeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This part of the application will be forwarded to our work partners, who will get in touch with an offer of work, transportation and housing. Please note that selected parts of the information you provide here (your work experience, etc.) may be used to build a digital CV, which these work partners share with others."])},
      "stepTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 2 of 3"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* If the above information is not correct, please log in with the correct user."])}
    }
  },
  "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A-team Program ", _interpolate(_named("currentYear")), " - ", _interpolate(_named("nextYear"))])},
  "weekdays": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])}
  },
  "weekdaysShort": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wed"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sat"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sun"])}
  }
}